export default {
  email: "Email",
  password: "Password",
  log_in: "Log in",
  stay_signed_in: "Stay signed in",
  forgot_password: "I forgot password",
  reset_password: "Reset password",
  reset_password_email: "Password reset email",
  resend_verification_email: "Re-send verification email",
  title: "Log in",
  log_in_message: "I want to login",
  login_button: "Login",
  or_use_social: "Or sign in/up with",
  type_email: "Type your email",
  type_password: "Type your password",
  registration_title: "Register",
  registration_message: "I want to register",
  register_button: "Register",
  password_again: "Password again",
  missing_password: "Password is required.",
  missing_email: "Email is required.",
  un_matched_passwords: "Passwords do not match",
  password_too_short: "Password need to be at least 6 characters long",
  cancel: "Cancel",
  registration_success_message: `Your account has been successfully created. A verification email has been sent to your email address.
  Please finalize the registration process by click the link in the verification email. MAKE SURE TO CHECK YOUR SPAM FOLDER!`,
  google_signin: "Sign in with Google",
  facebook_signin: "Sign in with Facebook",
  session_expired: "Session expired, please login again.",

  messages: {
    successful_password_reset: "A password reset email has been successfully send to your email address.",
    email_verification_resend: `Verification email has been sent to your email address.
    Please finalize the verification process by click the link in the verification email. MAKE SURE TO CHECK YOUR SPAM FOLDER!`,
  }
}
